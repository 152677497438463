@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

body {
    background-color: #fafafa;
    color: #333333;
    margin: 0;
    font-family: 'Montserrat', 'Roboto', sans-serif;
}

.sidebar {
  min-height: calc(100vh - 8rem);
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

footer {
  margin-top: auto;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollbar {
    --scrollbar-track: initial;
    --scrollbar-thumb: initial;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
    overflow: overlay;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
  }
  
  .scrollbar {
    scrollbar-width: auto;
  }
  
  .scrollbar::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  
  .scrollbar-thin {
    --scrollbar-track: initial;
    --scrollbar-thumb: initial;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
    overflow: overlay;
  }
  
  .scrollbar-thin::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
  }
  
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
  }
  
  .scrollbar-thin {
    scrollbar-width: thin;
  }
  
  .scrollbar-thin::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

body, html {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.right-chat-msg {
    position: absolute;
    right: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

option:disabled {
    color: lightgray;
}

.chat-input > input:disabled {
    opacity: .5;
}

.chat-input > button:disabled, .chat-input > div > button:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.center-loader {
    position: relative;
}
.center-loader > div {
    position: absolute;
    left: 46%;
    margin-top: 50%;
}

.clickable {
    cursor: pointer;
}

/* Absolute Center Spinner */
.loading-screen {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading-screen:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      background: radial-gradient(rgba(95, 95, 95,.8), rgba(37, 36, 36, .8));
    background: -webkit-radial-gradient(rgb(99 95 95 / 80%), rgb(37 36 36 / 80%));
  }

  .loading-screen-white:before {
      background: radial-gradient(rgba(253, 252, 252, 0.8), rgba(231, 230, 230, 0.8));
    background: -webkit-radial-gradient(rgb(253 252 252 / 80%), rgb(231 230 230 / 80%));
  }

  .loading-screen-full-white:before {
    background: radial-gradient(rgba(253, 252, 252), rgba(231, 230, 230));
  background: -webkit-radial-gradient(rgb(253 252 252), rgb(231 230 230));
}
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading-screen:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }



  .h-divider {
    width: 100%;
    height: 1px;
  }




  .inputBox {
    position: relative;
  }

  .inputField {
    -webkit-appearance: none;
    padding: 10px 16px;
    background-color: #fafafa;
    transition: all 300ms;
    &:focus, &:not(:placeholder-shown) {
      & + .inputLabel {
        left: 10px;
        top: -11px;
  
        font-size: 0.8rem;
      }
    }
  }

  .inputLabel {
    position: absolute;
    left: 10px;
    top: 7px;
      
    background-color: #fafafa;
    padding: 0 0.5em;
  
    color: #888;
    
    pointer-events: none;
    transition: all 300ms;
  }

  .inputLabel-raised {
    left: 10px !important;
    top: -11px !important;
    font-size: 0.8rem !important;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }

  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.sidebar.bg-transparent.text-bodyText.flex.flex-col.flex-1.border-0.overflow-auto.divide-y.divide-gray-100.scrollbar-thin.css-dev-only-do-not-override-gzal6t {
    border: none;
}